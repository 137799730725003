import React from "react";
import { Text } from "@components";

const TeeTimeBox = () => {
  return (
    <div
      className={`w-[160px] h-[70px] rounded-md flex flex-col items-center justify-center border-[3px] border-shadow`}
    >
      <div className="">
        <Text className="text-[18px] font-bold">{"12 Sept"}</Text>
      </div>
      <div className="">
        <Text className="text-[13px] ">{"Thursday"}</Text>
      </div>
    </div>
  );
};

export default TeeTimeBox;
