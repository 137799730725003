import React, { useRef } from "react";
import GoogleMap from "google-maps-react-markers";

const Map = () => {
  const mapRef = useRef(null);

  const onGoogleApiLoaded = ({ map }) => {
    mapRef.current = map;
  };

  return (
    <div className="min-w-[250px] w-full h-full rounded-lg flex relative ">
      <GoogleMap
        apiKey={"AIzaSyDL8YCB_yrs0zrQf982sXon1PG7nn--BqE"}
        defaultCenter={{
          lat: 52.520008,
          lng: 13.404954,
        }}
        defaultZoom={5}
        onGoogleApiLoaded={onGoogleApiLoaded}
        onChange={() => {}}
      ></GoogleMap>
    </div>
  );
};

export default Map;
