import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import tw from "tailwind-styled-components";
import Zoom from "@mui/material/Zoom";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Img, Participate_Button, Confirm_Button } from "@components";
import clubImg from "@assets/images/img3.png";
import theme from "../../theme";

const ClubCard = (props) => {
  let { display, onClick, data } = props;
  const { t } = useTranslation("common");

  const Date_View = () => (
    <div className="flex flex-row items-center justify-start sm2:gap-x-2 gap-x-1">
      <p className="xl2:text-[16px] xl1:text-[15px] sm2:text-[15px] text-[14px]">
        {t("home.date")}
      </p>
      <p className="bg-shadow text-center rounded-2xl px-3 xl2:text-[16px] xl1:text-[15px] sm2:text-[15px] text-[14px]">
        {moment().format(" ddd, MMM D ")}
      </p>
    </div>
  );

  const Time_View = () => (
    <div className="flex flex-row items-center justify-start gap-x-2">
      <p className="xl2:text-[16px] xl1:text-[15px] sm2:text-[15px] text-[14px]">
        {t("home.time")}
      </p>
      <p className="w-auto text-red font-semibold xl2:text-[16px] xl1:text-[15px] sm2:text-[15px] text-[14px] tracking-wide">
        {moment().format(" h:mm A ")}
      </p>
    </div>
  );

  const Title_View = (item) => (
    <Tooltip
      title={data.title}
      placement="top"
      TransitionComponent={Zoom}
      followCursor
      arrow
    >
      <p
        className={`${heading} ${item.className} ${
          display === "grid" ? "xl2:w-auto w-full" : "md:w-auto w-full"
        }`}
      >
        {data.title}
      </p>
    </Tooltip>
  );

  const Btn_View = () => (
    <>
      {false ? (
        <Confirm_Button type="confirmed">{"Confirmed"} </Confirm_Button>
      ) : (
        <Participate_Button onClick={onClick} />
      )}
    </>
  );

  return (
    <>
      {display === "grid" ? (
        <GridRoot>
          <div className={imgView}>
            <Img src={clubImg} />
          </div>

          <GridComponent>
            <Title_View className="lg:pb-4 pb-2 tracking-wide" />

            <GridContainer>
              <div className={grinDateTime}>
                <Date_View />
                <Time_View />
              </div>
              <Btn_View />
            </GridContainer>
          </GridComponent>
        </GridRoot>
      ) : (
        <ListRoot>
          <div className={imgView2}>
            <Img src={clubImg} />
          </div>

          <ListComponent>
            <Title_View />

            <ListContainer>
              <div className={listDateTime}>
                <Date_View />
                <div className="md2:flex hidden">
                  <Time_View />
                </div>
                <div className="md2:hidden flex">
                  <Btn_View />
                </div>
              </div>
              <ArrowIcon>
                <IconButton
                  onClick={onClick}
                  sx={{
                    ":hover": {
                      color: theme.colors.primary,
                    },
                  }}
                >
                  <KeyboardArrowRightIcon />
                </IconButton>
              </ArrowIcon>
            </ListContainer>

            <div className="md2:flex hidden">
              <Btn_View />
            </div>
            <div className="md2:hidden flex">
              <Time_View />
            </div>
          </ListComponent>
        </ListRoot>
      )}
    </>
  );
};

const GridRoot = tw.div`
w-[48%] 
min-w-auto 
h-auto 
flex flex-row items-center justify-start 
xl3:gap-x-4 sm2:gap-x-2 sm1:gap-x-1 gap-x-[1px] 
bg-shadow 
rounded-md 
xl3:p-3 md2:p-2 p-1
`;

const ListRoot = tw.div`
w-full 
h-auto 
flex flex-row items-center justify-between 
lg:gap-x-6 sm2:gap-x-3 gap-x-1.5 
bg-shadow 
rounded-md 
lg:p-3 p-2
`;

const imgView = `
xl:flex hidden
xl3:w-[150px] xl2:w-[140px] xl1:w-[140px] w-[110px]
xl3:min-w-[150px] xl2:min-w-[140px] xl1:min-w-[140px] min-w-[110px] 
max-w-[150px] 
xl3:h-[150px] xl2:h-[140px] xl1:h-[140px] h-[110px]
xl3:min-h-[150px] xl2:min-h-[140px] xl1:min-h-[140px] min-h-[110px] 
max-h-[165px] 
rounded-md 
`;

const imgView2 = `
lg:w-[20%] md:w-[140px] sm2:w-[110px] w-[90px] 
lg:min-w-[150px] md:min-w-[140px] sm2:min-w-[110px] min-w-[90px] 
max-w-[150px] 
lg:h-[150px] md:h-[140px] sm2:h-[110px] h-[90px] 
rounded-md 
sm1:flex hidden
`;

const GridComponent = tw.div`
lg:w-full w-full
xl3:h-[150px] xl2:h-[140px] xl1:h-[140px] lg:h-[110px] h-auto 
flex flex-col items-start justify-between 
truncate 
bg-white 
xl3:p-4 xl2:p-2 md2:p-2 p-1 
`;

const ListComponent = tw.div`
w-full 
lg:h-[150px] md:h-[140px] sm2:h-[110px] h-[90px] 
flex flex-col items-start md:justify-around justify-between 
md:gap-y-4 gap-y-1 
bg-white 
rounded-md 
lg:p-4 sm2:p-2 p-1 
truncate
`;

const GridContainer = tw.div`
w-full 
h-full 
flex flex-col items-start justify-around 
gap-y-2
`;

const ListContainer = tw.div`
w-full 
h-auto 
flex flex-row items-center justify-between 
sm2:gap-x-2 gap-x-0
`;

const heading = `
font-bold 
lg:text-[17px] sm2:text-[16px] text-[15px] 
truncate 
`;

const grinDateTime = `
w-full 
h-auto 
flex lg:flex-row flex-col 
lg:items-center items-start 
justify-start 
gap-x-3 gap-y-2
`;

const listDateTime = `
md2:w-auto w-full 
h-auto 
flex flex-row 
items-center 
md2:justify-start justify-between 
md2:gap-x-6 sm2:gap-x-3 gap-x-1
`;

const ArrowIcon = tw.div`
w-[30px] 
h-[30px] 
md2:flex hidden 
items-center 
justify-center 
hover:cursor-pointer 
hover:text-primary
`;

export default ClubCard;
