import axios from "axios";
// import { Storage } from "@utils";
import { API_URL, LOGIN_USER, TOURNAMENTS } from "@utils/config";

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    "X-Gravitee-Api-Key": "294acbc5-40f2-4b5e-9655-88b9cd0b2857",
  },
  timeout: 1000000,
});

// instance.interceptors.request.use(
//   async function (request) {
//     let userDetails = await Storage.get(Storage.USER_TOKEN);
//     if (userDetails) {
//       let token = localStorage.getItem("UserToken");
//       request.headers.authorization = `Bearer ${token}`;
//     }
//     return request;
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );

// instance.interceptors.response.use(
//   function (response) {
//     return response;
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );

export const loginUserApi = (data) => {
  return instance.post(LOGIN_USER, data);
};

export const tournamentsApi = (data) => {
  return instance.get(`${TOURNAMENTS}?club_id=${12345}`);
};
