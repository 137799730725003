import { createSlice } from "@reduxjs/toolkit";
import { loginUser } from "./actions";
import { Storage } from "@utils";

const initialState = {
  userData: null,
};

const slice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUserDetails: (state, action) => {
      state.userData = action?.payload;
    },
    logoutUser: (state) => {
      state.userData = null;
      Storage.remove(Storage.USER_DATA);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      if (action.payload.status === 200 && action.payload.data.data) {
        state.userData = action?.payload?.data?.data;
        Storage.save(
          Storage.USER_DATA,
          JSON.stringify(action?.payload?.data?.data)
        );
      }
    });
  },
});

export const { setUserDetails, logoutUser } = slice.actions;

export default slice.reducer;
