import homeReducer from "@redux/home/slice";
import settingsReducer from "@redux/settings/slice";
import authReducer from "@redux/authentication/slice";
import { combineReducers } from "@reduxjs/toolkit";

const rootReducer = combineReducers({
  authReducer,
  homeReducer,
  settingsReducer,
});

export default rootReducer;
