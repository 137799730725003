import React, { useRef } from "react";
import { DateRange } from "react-date-range";
import DateRangeIcon from "@mui/icons-material/DateRange";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import theme from "../../theme.js";
import "./datePicker.css";
import "../../App.css";

const DatePickers = (props) => {
  const ref = useRef(null);
  let { setClose, setOpen, value, open, changeDate, startDate, endDate } =
    props;

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setClose();
    }
  };

  React.useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className="date-range-picker-input">
      <div
        className="date-input-wrapper sm1:w-[200px] w-auto"
        onClick={setOpen}
      >
        <DateRangeIcon className="text-primary" />
        <input
          type="text"
          value={value}
          readOnly
          className="date-input bg-shadow"
        />
      </div>
      {open && (
        <div
          ref={ref}
          className="date-range-picker-popover sm1:w-[350px] w-[300px] sm1:p-2 p-0 overflow-scroll custom-scroll"
        >
          <DateRange
            editableDateInputs={true}
            onChange={changeDate}
            moveRangeOnFirstSelection={false}
            ranges={[
              {
                startDate: startDate,
                endDate: endDate,
                key: "selection",
              },
            ]}
            rangeColors={[theme.colors.primary]}
            minDate={new Date()}
          />
        </div>
      )}
    </div>
  );
};

export default DatePickers;
