const theme = {
  dark: false,
  colors: {
    primary: "rgb(4,157,62)",
    black: "rgb(0,0,0)",
    shadow: "rgba(240,240,240,0.8)",
    textShadow: "rgb(139,139,139)",
    darkShadow: "rgb(48,55,75)",
    white: "rgb(255,255,255)",
    red: "rgb(255,0,0)",
  },
};

export default theme;
