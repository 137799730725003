import { createAsyncThunk } from "@reduxjs/toolkit";
import { LOGIN_USER } from "./types";
import { loginUserApi } from "@services/apis";

export const loginUser = createAsyncThunk(LOGIN_USER, async (data) => {
  try {
    const response = await loginUserApi(data);
    return response;
  } catch (err) {
    return err;
  }
});
