import React from "react";
import { useTranslation } from "react-i18next";
import tw from "tailwind-styled-components";
import Dialog from "@mui/material/Dialog";
import { Zoom } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import logoIcon from "@assets/images/logo2.png";
import IconButton from "@mui/material/IconButton";
import { Text, Img, CustomInput, MuiButton } from "@components";
import theme from "../../theme";

function LoginModal(props) {
  let {
    openModal,
    closeModal,
    changeEmail,
    email,
    changePassword,
    password,
    showPassword,
    handleShowPassword,
    btnLoading,
    emailError,
    validEmailError,
    passwordError,
    login,
  } = props;
  const { t } = useTranslation("common");

  return (
    <Dialog
      open={openModal}
      // onClose={closeModal}
      TransitionComponent={Zoom}
      PaperProps={{
        sx: {
          margin: "0%",
        },
      }}
      slotProps={{
        backdrop: {
          sx: {
            backdropFilter: "blur(5px)",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          },
        },
      }}
    >
      <div className="w-full h-full">
        <div className="w-full flex self-end justify-end">
          <IconButton onClick={closeModal}>
            <CloseIcon fontSize="large" sx={{ color: theme.colors.primary }} />
          </IconButton>
        </div>
        <Container>
          <div className="w-[78px] h-[70px]">
            <Img src={logoIcon} />
          </div>
          <Text className="font-bold text-[20px] text-center mt-4 tracking-widest">
            {t("home.login")}
          </Text>
          <div className="w-full pt-4">
            <CustomInput
              change_Value={changeEmail}
              value={email}
              placeholder={t("home.enter_Email")}
              label={t("home.email")}
              error={emailError || validEmailError}
              errorMessage={
                emailError
                  ? t("home.enter_EmailAddress")
                  : validEmailError
                  ? t("home.enter_ValidEmail")
                  : ""
              }
            />
          </div>
          <div className="w-full pt-4">
            <CustomInput
              change_Value={changePassword}
              value={password}
              placeholder={t("home.enter_Password")}
              label={t("home.password")}
              showPassword={showPassword}
              handleShowPassword={handleShowPassword}
              error={passwordError}
              errorMessage={passwordError ? t("home.enter_Password") : ""}
            />
          </div>
          <div className="w-full h-[56px] mt-10">
            <MuiButton onClick={login} loading={btnLoading} height={"50px"}>
              {" "}
              {t("home.login")}{" "}
            </MuiButton>
          </div>
        </Container>
      </div>
    </Dialog>
  );
}

const Container = tw.div`
lg:w-[540px] md2:w-[500px] md:w-[440px] sm2:w-[360px] sm1:w-[330px] w-[300px] 
h-auto 
rounded-md 
bg-white 
flex flex-col items-center justify-start 
gap-1 
pt-0
lg:px-8 px-4
lg:pb-8 pb-4

`;

export { LoginModal };
