import { createAsyncThunk } from "@reduxjs/toolkit";
import { TOURNAMENTS } from "./types";
import { tournamentsApi } from "@services/apis";

export const getTournamentsData = createAsyncThunk(
  TOURNAMENTS,
  async (data) => {
    try {
      const response = await tournamentsApi(data);
      return response;
    } catch (err) {
      return err;
    }
  }
);
