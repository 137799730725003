const SERVER_HOST = process.env.REACT_APP_API_URL;
const STRIPE_KEY = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
const GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;

export const API_URL = SERVER_HOST;

export const STRIPE_PUBLISH_KEY = STRIPE_KEY;
export const GOOGLE_MAP_KEY = GOOGLE_MAP_API_KEY;

export const LOGIN_USER = "user/login";
export const TOURNAMENTS = "tournament/tournaments";

const Config = {
  SERVER_HOST,
};

export default Config;
