import { createSlice } from "@reduxjs/toolkit";
import { getTournamentsData } from "./actions";

const initialState = {
  paginationNumber: 1,
  tournaments: [],
};

const slice = createSlice({
  name: "home",
  initialState,
  reducers: {
    setPaginationNumber: (state, action) => {
      state.paginationNumber = action?.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTournamentsData.fulfilled, (state, action) => {
      if (action.payload.status === 200 && action.payload.data.data) {
        state.tournaments = action?.payload?.data?.data;
      }
    });
  },
});

export const { setPaginationNumber } = slice.actions;
export default slice.reducer;
